import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpService } from 'src/app/core/services/http/http.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DoctorProfileComponent } from '../doctor-profile/doctor-profile.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LoaderService } from 'src/app/core/services/loader.service';
import * as moment from 'moment';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.css']
})
export class DoctorListComponent implements OnInit {
  displayedColumns: string[] = ['Sr.No.', 'fullName', 'email', 'mobileNo', 'URL', 'Action'];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  doctorList: any[] = [];
  showLaoder = false;
  user: any;

  constructor(
    private http: HttpService,
    public dialog: MatDialog,
    private router: Router,
    private toast: ToastrService,
    private authService: AuthenticationService,
    private loaderService: LoaderService
  ) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      this.user = user.userDetails;
    } else {
      this.http.get('api/user/token').subscribe((response: any) => {
        if (response.status === 200) {
          this.user = response.data;
        }
      });
    }
  }

  ngOnInit() {
    this.getDoctorList();
  }

  getDoctorList() {
    this.doctorList = [];
    this.loaderService.isLoading.next(true);
    this.http.get('users/doctors').subscribe((response: any) => {
      this.loaderService.isLoading.next(false);
      if (response.status === 200) {
        response.data.forEach((ele: any) => {
          this.doctorList.push(
            {
              _id: ele._id,
              fullName: ele.fullName,
              email: ele.email,
              mobileNo: ele.mobileNo,
              url: ele.roomName,
              status: ele.status === 'ACTIVE' ? true : false,
              isAvailableToBook: ele.isAvailableToBook
            });
        });
        this.dataSource = new MatTableDataSource(this.doctorList);
      } else {
        this.toast.error(response.message);
      }
    }, (error) => {
      this.loaderService.isLoading.next(false);
      this.toast.error(error);
    });
  }

  updateBookingAvailability(checked, doctorId) {
    this.http.put('api/admin/doctor/status', { isAvailableToBook: checked, doctorId }).subscribe(res => {
      console.log(res);
      if (res['status'] === 200) {
        this.toast.success('Doctor status updated');
        this.getDoctorList();
      } else {
        this.toast.error(res['message']);
      }
    }, err => {
      this.toast.error('Something went wrong');
    });
  }

  logout() {
    this.authService.logout();
  }

  onToggle(event, id) {
    let status;
    if (event.checked) {
      status = 'ACTIVE';
    } else {
      status = 'INACTIVE';
    }
    const data = {
      userId: id,
      status,
      date: moment().format('YYYY-MM-DD')
    };
    this.loaderService.isLoading.next(true);
    this.http.put('api/admin/user/status', data).subscribe((res: any) => {
      this.loaderService.isLoading.next(false);
      if (res.status === 200) {
        this.toast.success(res.message, `User status ${status}`, { timeOut: 3000 });
      } else {
        this.loaderService.isLoading.next(false);
        this.toast.error(res.message, '', { timeOut: 3000 });
      }
    }, err => {
      this.getDoctorList();
      this.loaderService.isLoading.next(false);
      // console.log(err);
      this.toast.error(err, '', { timeOut: 2000 });
    });
  }

  addDoctor() {
    const dialogRef = this.dialog.open(DoctorProfileComponent, {
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteDoctor(id): void {
    const message = `Are you sure you want to delete doctor?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      console.log(result);
      if (result) {
        this.loaderService.isLoading.next(true);
        this.http.delete('api/admin/users/' + id).subscribe(res => {
          this.loaderService.isLoading.next(false);
          if (res['status'] === 200) {
            this.toast.success('Doctor deleted successfully', '');
            this.getDoctorList();
          }
        }, err => {
          this.loaderService.isLoading.next(false);
          this.toast.error('Somthing went wrong', '');
        });
      }
    });
  }
  gotoDashboard() {
    this.router.navigate(['/app/dashboard']);
  }

  goToDoctorsListing() {
    this.router.navigate(['/app/dashboard/doctors']);
  }

  gotoIntakeForms() {
    this.router.navigate(['/app/dashboard/intake-forms']);
  }
}

