import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { AuthenticationService } from '../services/authentication.service'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthenticationService, private http: HttpService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.http.getAccessToken()}`
      }
    });
    return next.handle(request);
  }
}