import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpService } from 'src/app/core/services/http/http.service';
// import { BookAppointmentPopupComponent } from '../book-appointment-popup/book-appointment-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DoctorProfileComponent } from '../doctor-profile/doctor-profile.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ViewIntakeFormComponent } from '../view-intake-form/view-intake-form.component';
import { environment } from '../../../../environments/environment';
import { LoaderService } from 'src/app/core/services/loader.service';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = [
  'maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple', 'fuchsia', 'lime', 'teal',
  'aqua', 'blue', 'navy', 'black', 'gray'
];



@Component({
  selector: 'app-intake-forms',
  templateUrl: './intake-forms.component.html',
  styleUrls: ['./intake-forms.component.css']
})
export class IntakeFormsComponent implements OnInit {
  displayedColumns: string[] = ['Sr.No.', 'Name', 'Date Created', 'Date Updated', 'Action'];
  dataSource: MatTableDataSource<UserData>;
  noData = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  intakeForms: any;
  showLaoder: boolean = false;
  user: any;

  constructor(
    private http: HttpService,
    public dialog: MatDialog,
    private router: Router,
    private toast: ToastrService,
    private authService: AuthenticationService,
    private loaderService: LoaderService
  ) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      this.user = user.userDetails;
    } else {
      this.http.get('api/user/token').subscribe((response: any) => {
        if (response.status === 200) {
          this.user = response.data;
        }
      });
    }
  }

  ngOnInit() {
    const userdetails = JSON.parse(localStorage.getItem("currentUser"));
    // this.user = userdetails.userData.userId;
    this.showLaoder = true;
    this.intakeForms = [];
    this.dataSource = new MatTableDataSource(this.intakeForms);
    this.getIntakeForms();
  }

  getIntakeForms() {
    this.loaderService.isLoading.next(true);
    this.http.get('admin/questionairesResponse').subscribe((response: any) => {
      this.loaderService.isLoading.next(false);
      if (response.status === 200) {
        console.log(response['data']);

        response['data'].forEach((ele: any) => {
          console.log(ele);
          this.intakeForms.push(
            {
              _id: ele.userId._id,
              fullName: ele.userId.fullName,
              createdDate: ele.createdDate,
              updatedDate: ele.updatedDate,
              intakeForm: ele
            });
        });
        this.dataSource = new MatTableDataSource(this.intakeForms);
      } else if (response.status === 204) {
        this.noData = true;
      } else {
        this.toast.error(response.message);
      }
    }, (error) => {
      this.loaderService.isLoading.next(false);
      this.toast.error(error);
    });
  }

  viewIntakeForm(id) {
    console.log(id);
    const dialogRef = this.dialog.open(ViewIntakeFormComponent, {
      autoFocus: false,
      data: {
        userId: id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  // doctorDetails(id) {
  //   console.log('doctor id:', id)
  //   if (id) {
  //     const dialogRef = this.dialog.open(BookAppointmentPopupComponent, {
  //       // width: '250px',
  //       data: { doctor_id: id}
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //       if(result=="success"){
  //         this.router.navigate(['/app/dashboard/patient-booking'])
  //       }
  //     });
  //   }
  // }

  editIntakeForm(intakeForm) {
    console.log(intakeForm);
    localStorage.setItem('intakeForm', JSON.stringify(intakeForm));
    this.router.navigate(['/app/dashboard/edit-intake-form/' + intakeForm.userId._id]);
  }

  addDoctor() {
    const dialogRef = this.dialog.open(DoctorProfileComponent, {
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  downloadPdf(userId) {
    window.open(environment.intakeFormBaseUrl + userId + '.pdf', '_blank');
  }
  gotoDashboard() {
    this.router.navigate(['/app/dashboard']);
  }

  goToDoctorsListing() {
    this.router.navigate(['/app/dashboard/doctors']);
  }

  logout() {
    this.authService.logout();
  }


}
