
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../services/http/http.service';

export class User {
  user_id: number;
  firstName: String;
  lastName: String;
  password: string;
  user_role: string;
  token?: string;
}

@Injectable()
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private httpService: HttpService, private router: Router, private toast: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  isAuthenticated(): boolean {
    try {
      const loginData = JSON.parse(localStorage.getItem('token'));
      if (loginData) {
        return true;
      }
    } catch (error) {
      console.log('access token not found');
      this.toast.warning('You are not allowed to view this page');
      return false;
    }
  }

  checkToken(data) {
    let url = '';
    const accessToken = data.accessToken;
    url = `oauth/check_token?accessToken=${accessToken}`;
    this.httpService.get(url).subscribe((res: any) => {
      if (res.userType === 'ADMINISTRATOR') {
        res.isAdmin = true;
      } else { res.isAdmin = false; }
      localStorage.setItem('userData', JSON.stringify(res));
    });
  }

  refreshTokenAPI() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    console.log('***Intercepting hitting refreshtoken api , login data : ', loginData);
    const url = `oauth/token?grant_type=refresh_token&refresh_token=${loginData.refresh_token}`;
    console.log('***Intercepting url : ', url);
    return this.httpService.postLogin(url, {});
  }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string, offset: string) {
    const loginData = `username=${username}&password=${password}&offset=${offset}&grant_type=password&client_id=health&client_secret=health-pathway`;
    return this.httpService.postLogin('oauth/token', loginData)
      .pipe(map(user => {
        if (user && user.accessToken) {
          localStorage.setItem('token', JSON.stringify(user.accessToken));
          this.httpService.get('api/user/token').subscribe((response: any) => {
            if (response.data.role === 'ADMIN') {
              const userDetails = { userDetails: response.data };
              localStorage.setItem('currentUser', JSON.stringify(userDetails));
              this.currentUserSubject.next(user);
              this.toast.success('Welcome you have logged in successfully!!', '', { timeOut: 3000 });
              this.router.navigateByUrl('app/dashboard');
            } else {
              this.toast.error('access denied!!', '', { timeOut: 3000 });
              this.router.navigateByUrl('login');
            }
          });
        } else {
          this.toast.error(user.message, '', { timeOut: 3000 });
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

}
