import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '../shared/shared.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularMaterial } from '../../angular-material';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { HomePageComponent } from './home-page/home-page.component';
import { MatDialogModule, MatNativeDateModule } from '@angular/material';
import { IntakeFormsComponent } from './intake-forms/intake-forms.component';
import { DoctorListComponent } from './doctor-listing/doctor-list.component';
import { DoctorProfileComponent } from './doctor-profile/doctor-profile.component';
import { ReschedulePopupComponent } from '../reschedule-popup/reschedule-popup.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ViewIntakeFormComponent } from './view-intake-form/view-intake-form.component';
import { DoctorsOnlineListComponent } from './doctors-online-list/doctors-online-list.component';
import { AppointmentSuccessfullPopupComponent } from '../appointment-successfull-popup/appointment-successfull-popup.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DashboardComponent } from './dashboard.component';
import { CreateAppointmentComponent } from './create-appointment/create-appointment.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { AppointmentsComponent } from './appointments/appointments.component';
import { SettingComponent } from './setting/setting.component';

// learn more about this from
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
export const MY_NATIVE_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

@NgModule({
  declarations: [
    HomePageComponent,
    DashboardComponent,
    ReschedulePopupComponent,
    ConfirmationPopupComponent,
    AppointmentSuccessfullPopupComponent,
    DoctorListComponent,
    HeaderComponent,
    FooterComponent,
    DoctorProfileComponent,
    IntakeFormsComponent,
    ViewIntakeFormComponent,
    DoctorsOnlineListComponent,
    ConfirmDialogComponent,
    CreateAppointmentComponent,
    AppointmentsComponent,
    SettingComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ReactiveFormsModule,
    AngularMaterial,
    FlexLayoutModule,
    SharedModule,
    MatChipsModule,
    MatCardModule,
    MatRadioModule,
    FormsModule,
    MatNativeDateModule,
    MatDialogModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule

  ],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
  ],
  entryComponents: [
    ReschedulePopupComponent,
    ConfirmationPopupComponent,
    DoctorProfileComponent,
    AppointmentSuccessfullPopupComponent,
    ConfirmDialogComponent,
    CreateAppointmentComponent
  ],
})
export class DashboardModule { }
