import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HttpService } from 'src/app/core/services/http/http.service';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from 'src/app/core/services/loader.service';
import * as moment from 'moment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

export interface Appointment {
  _id: string;
  doctorId: any;
  patientId: any;
  patientEmail: string;
  appointmentDate: string;
  status: string;
}

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {
  displayedColumns: string[] = ['Sr.No.', 'patientName', 'doctorName', 'appointmentDate', 'status'];
  dataSource: MatTableDataSource<Appointment>;

  showLaoder = false;
  startDate = moment().add({ days: -7 }).format();
  endDate = moment().format();
  appointmentList = [];
  doctorId = 'all';
  doctorsList = [];
  filterForm: FormGroup;
  todaysDate = moment().format();
  constructor(
    private http: HttpService,
    public dialog: MatDialog,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
    private toast: ToastrService
  ) {

  }

  ngOnInit() {
    this.buildForm();
    this.loaderService.isLoading.next(true);
    this.http.get('api/doctors').subscribe((res: any) => {
      console.log(res);

      this.loaderService.isLoading.next(false);
      this.doctorsList = res.data;
      console.log(this.doctorsList.length);

      if (res['data'].length > 0) {
        // this.doctorId = this.doctorsList[0]._id;
        // this.filterForm.value.doctorId = this.doctorId;
        this.getPastAppointments();
      }
    });
  }

  buildForm() {
    this.filterForm = this.formBuilder.group({
      doctorId: ['', [Validators.required]],
      startDate: [this.startDate, [Validators.required]],
      endDate: [this.endDate, [Validators.required]],
    });
  }
  filter(form) {
    if (this.filterForm.invalid) {
      return;
    }
    this.getPastAppointments();
  }


  getPastAppointments() {
    this.startDate = moment(this.filterForm.value.startDate).format('YYYY-MM-DD');
    this.endDate = moment(this.filterForm.value.endDate).format('YYYY-MM-DD');
    this.loaderService.isLoading.next(true);
    let url;
    if (this.doctorId === 'all') {
      url = `api/admin/pastAppointments?startDate=${this.startDate}&endDate=${this.endDate}`;
    } else {
      url = `api/admin/pastAppointments?doctorId=${this.filterForm.value.doctorId}&startDate=${this.startDate}&endDate=${this.endDate}`;
    }
    this.http.get(url).subscribe((res: any) => {
      console.log(res);
      this.appointmentList = [];
      this.loaderService.isLoading.next(false);
      if (res.status === 200) {
        res.data.forEach((ele: any) => {
          this.appointmentList.push(
            {
              _id: ele._id,
              patientName: ele.patientId.fullName,
              doctorName: ele.doctorId.fullName,
              email: ele.patientId.email,
              appointmentDate: ele.appointmentDate,
              status: ele.status
            });
        });
        this.dataSource = new MatTableDataSource(this.doctorsList);
        console.log(res);
      } else {
        this.toast.error(res.message);
        this.loaderService.isLoading.next(false);
      }
    }, err => {
      this.appointmentList = [];
      this.toast.error('Something went wrong');
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}

