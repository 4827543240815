import { Component, OnInit, Inject } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-view-intake-form',
  templateUrl: './view-intake-form.component.html',
  styleUrls: ['./view-intake-form.component.css']
})
export class ViewIntakeFormComponent implements OnInit {
  editeFlag = true;
  patient;
  sections;
  isLoading = false;
  formFilled = false;
  constructor(
    private http: HttpService,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<ViewIntakeFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    // get intake form of user
    this.isLoading = true;
    this.http.get('admin/questionairesResponse/' + this.data.userId).subscribe(res => {
      console.log(res);
      this.isLoading = false;
      if (res['status'] === 200) {
        this.formFilled = true;
        this.patient = res['data'].userId;
        this.sections = res['data'].responses;
      } else if (res['status'] === 204) {
        this.formFilled = false;
        // this.toast.error(res['message']);
      }
    }, err => {
      this.isLoading = false;
      this.toast.error('Something went wrong');
    });
  }

}
