import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/role-guard.service';
import { FullComponent } from './modules/shared/components/full/full.component';
import { AppBlankComponent } from './modules/shared/components/blank/blank.component';

const routes: Routes = [
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
        data: {
          breadcrumbs: true,
        }
      },

    ]
  },
  {
    path: 'app',
    component: FullComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          breadcrumbs: true,
          text: 'dashboard'
        }
      },
      // {
      //   path: 'intake-form/:id',
      //   redirectTo: 'intake-form'
      // },
      {
        path: 'intake-form',
        loadChildren: () => import('./modules/intake-form/intake-form.module').then(m => m.IntakeFormModule)
      },
    ]
  },
  {
    path: '**',
    redirectTo: '404'
  },

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }