import { ConfirmationPopupComponent } from '../../confirmation-popup/confirmation-popup.component';
import { ReschedulePopupComponent } from '../../reschedule-popup/reschedule-popup.component';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ViewIntakeFormComponent } from '../view-intake-form/view-intake-form.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  user: { id: string; uid: string, fullName: string; email: string; profileImage: string, imageUrl: string };
  todaysDate = moment().format();
  showLaoder = false;
  appointmentList: any[];
  currentDate: string;
  doctorsList: any[];
  waitingQueueList: any[];
  selectedDate: string;
  doctorId = 'all';

  constructor(private http: HttpService, private router: Router, public dialog: MatDialog, private toast: ToastrService,
    private authService: AuthenticationService, private loaderService: LoaderService) {

    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      this.user = user.userDetails;
    } else {
      this.loaderService.isLoading.next(true);
      this.http.get('api/user/token').subscribe((response: any) => {
        this.loaderService.isLoading.next(false);
        if (response.status === 200) {
          this.user = response.data;
        }
      });
    }
  }

  ngOnInit() {
    this.getScheduledAppointments();
    this.getDoctorsList();
  }

  // convert to 24 hours
  timeString12hr(timeString) {
    const H = +timeString.substr(0, 2);
    const h = H % 12 || 12;
    const ampm = H < 12 || H === 24 ? 'AM' : 'PM';
    timeString = h + timeString.substr(2, 3) + ampm;
    return timeString;
  }

  formatDate(date) {
    return moment(date).format('ddd DD MMM YYYY');
  }
  formatTime(date) {
    return moment(date).format('hh:mm A');
  }
  downloadRecoreding(appointmetId) {
    this.http.get('api/admin/appointment/recording/' + appointmetId).subscribe(res => {
      console.log(res);
      if (res['status'] === 200) {
        window.open(res['data'].recordingUrl);
      } else if (res['status'] === 204) {
        this.toast.warning('No recording found');
      } else {
        this.toast.error(res['message'])
      }
    }, err => {
      this.toast.error('Something went wrong');
    });
  }

  getWaitingAppointmentsByDoctorId(doctorId) {
    this.waitingQueueList = [];
    if (doctorId === 'all') {
      this.getWaitingQueuAppointments();
      return;
    }
    this.loaderService.isLoading.next(true);
    this.http.get(`api/admin/waitingAppointments/${doctorId}?date=${moment().format('YYYY-MM-DD')}`).subscribe(res => {
      this.loaderService.isLoading.next(false);
      if (res['status'] === 200) {
        this.waitingQueueList = res['data'];
      }
    }, err => {
      this.toast.error('Something went wrong');
      this.loaderService.isLoading.next(false);
    });
  }

  updatePaymentStatus(appointmentId, paymentStatus) {
    const data = {
      id: appointmentId,
      status: paymentStatus === true ? 'PAID' : 'UNPAID'
    };
    this.loaderService.isLoading.next(true);
    this.http.put('api/admin/appointments/paymentstatus', data).subscribe(res => {
      console.log(res);
      this.loaderService.isLoading.next(false);
      if (res['status'] === 200 || res['status'] === 204) {
        this.toast.success('Payment Status updated to ' + data.status);
      } else {
        this.toast.error(res['message']);
      }
    }, err => {
      this.loaderService.isLoading.next(false);
      this.toast.error('Something went wrong');
    });
  }
  getScheduledAppointments() {
    this.currentDate = moment(this.selectedDate).format('YYYY-MM-DD');
    this.loaderService.isLoading.next(true);
    this.http.get(`api/admin/bookedAppointments?appointmentDate=${this.currentDate}&appointmentType=VIDEO`).subscribe((res: any) => {
      this.appointmentList = res.data;
      console.log(this.appointmentList);

      this.loaderService.isLoading.next(false);
    });
    this.getWaitingQueuAppointments();
  }

  getDoctorsList() {
    this.loaderService.isLoading.next(true);
    this.http.get('api/doctors').subscribe((res: any) => {
      this.loaderService.isLoading.next(false);
      this.doctorsList = res.data;
    });
  }

  getWaitingQueuAppointments() {
    this.loaderService.isLoading.next(true);
    this.http.get(`api/admin/waitingAppointments?status=WAITING&date=${moment().format('YYYY-MM-DD')}`).subscribe((res: any) => {
      this.loaderService.isLoading.next(false);
      this.waitingQueueList = res.data;
    });
  }
  onDateChange(date: Date) {
    this.selectedDate = moment(date).format('YYYY-MM-DD');
    if (this.doctorId === '') {
      this.getScheduledAppointments();
    } else {
      this.doctorAppointments(this.doctorId);
    }
  }

  doctorAppointments(appointment) {
    console.log(appointment);
    if (appointment === 'all') {
      this.getScheduledAppointments();
      return;
    }
    this.appointmentList = [];
    if (this.selectedDate) {
      this.currentDate = this.selectedDate;
    }
    this.loaderService.isLoading.next(true);
    this.http.get(`api/admin/doctors/bookedAppointments?doctorId=${appointment}&appointmentDate=${this.currentDate}&appointmentType=VIDEO`).subscribe((res: any) => {
      this.appointmentList = res.data;
      this.loaderService.isLoading.next(false);
    });
  }

  gotoDashboard() {
    this.router.navigate(['/app/dashboard']);
  }

  reScheduleAppointment(appointment) {
    if (!appointment) {
      return this.toast.warning('Appointment id not found', '', { timeOut: 2000 });
    }
    if (!(appointment.status === 'BOOKED' || appointment.status === 'RESCHEDULED')) {
      return this.toast.warning('Appointment is already ' + appointment.status, '', { timeOut: 2000 });
    }
    const dialogRef = this.dialog.open(ReschedulePopupComponent, {
      data: { appointment }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.getScheduledAppointments();
      }
    });
  }

  cancelAppointment(appointment) {
    if (!appointment) {
      return this.toast.warning('Appointment id not found', '', { timeOut: 2000 });
    }

    if (!(appointment.status === 'BOOKED' || appointment.status === 'RESCHEDULED')) {
      return this.toast.warning('Appointment is already ' + appointment.status, '', { timeOut: 2000 });
    }

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      data: appointment
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        const payload = { id: appointment._id, status: 'CANCELLED' };
        this.loaderService.isLoading.next(true);
        this.http.put('api/admin/cancelAppointments', payload).subscribe((response: any) => {
          this.loaderService.isLoading.next(false);
          if (response.status === 200) {
            this.toast.success(response.message, 'Cancelled', { timeOut: 2000 });
            this.getScheduledAppointments();
          } else {
            this.toast.error(response.message);
          }
        }, (error) => {
          this.loaderService.isLoading.next(false);
          this.toast.error(error, '', { timeOut: 3000 });
        });
      }
    });
  }

  viewIntakeForm(id) {
    console.log(id);
    const dialogRef = this.dialog.open(ViewIntakeFormComponent, {
      autoFocus: false,
      data: {
        userId: id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  isPastDateTime(date) {
    if (moment(date).isBefore(moment().toISOString())) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.authService.logout();
  }
}
