import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  response: string;
  type: string;
}

@Component({
  selector: 'app-appointment-successfull-popup',
  templateUrl: './appointment-successfull-popup.component.html',
  styleUrls: ['./appointment-successfull-popup.component.css']
})
export class AppointmentSuccessfullPopupComponent implements OnInit {

  message: string

  constructor(public dialogRef: MatDialogRef<AppointmentSuccessfullPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    if (this.data.type == "post") {
      this.message = "booked"
    } else if (this.data.type == "put") {
      this.message = "rescheduled"
    } else {
      this.message = "booked"
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
