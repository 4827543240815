import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.css']
})
export class ConfirmationPopupComponent implements OnInit {
  showLaoder = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      // console.log(data);
    }

  ngOnInit() {
  }

  timeString12hr(timeString) {
    // var timeString = "18:00:00";
    const H = +timeString.substr(0, 2);
    const h = H % 12 || 12;
    const ampm = H < 12 || H === 24 ? 'AM' : 'PM';
    timeString = h + timeString.substr(2, 3) + ampm;
    return timeString;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  submit() {
    this.dialogRef.close('success');
  }

}
