import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMSPWD = [
  {
    state: 'app/dashboard/profile-details',
    name: 'Profile',
    type: 'extLink',
    icon: 'account_circle'
  },
  {
    state: 'app/dashboard',
    name: 'Dashboard',
    type: 'extLink',
    icon: 'web_asset'
  }
];

const MENUITEMS = [
  // {
  //   state: 'app/videoSession',
  //   name: 'Video Sessions',
  //   type: 'link',
  //   icon: 'play_circle_outline'
  // }, 

];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    // let role = JSON.parse(localStorage.getItem("currentUser")).userData.userId.role;
    // if (role == "EDUCATOR") {
    //   return MENUITEMS;
    // } else {
      return MENUITEMSPWD;
    // }
  }
}