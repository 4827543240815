import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { DoctorListComponent } from './doctor-listing/doctor-list.component';
import { DoctorProfileComponent } from './doctor-profile/doctor-profile.component';
import { IntakeFormsComponent } from './intake-forms/intake-forms.component';
import { DashboardComponent } from './dashboard.component';
import { ViewIntakeFormComponent } from './view-intake-form/view-intake-form.component';
import { CreateAppointmentComponent } from './create-appointment/create-appointment.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { SettingComponent } from './setting/setting.component';
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: '',
        component: HomePageComponent
      },
      {
        path: 'doctors',
        component: DoctorListComponent
      },
      {
        path: 'past-appointments',
        component: AppointmentsComponent
      },
      {
        path: 'add-doctor',
        component: DoctorProfileComponent
      },
      {
        path: 'edit-doctor/:_id',
        component: DoctorProfileComponent
      },
      {
        path: 'intake-forms',
        component: IntakeFormsComponent
      },
      {
        path: 'view-intake-form',
        component: ViewIntakeFormComponent
      },
      {
        path: 'create-appointment',
        component: CreateAppointmentComponent
      },
      {
        path: 'setting',
        component: SettingComponent
      },
      {
        path: 'question',
        loadChildren: () => import('../../modules/question/question.module').then(m => m.QuestionModule),
      },
      {
        path: 'edit-intake-form/:id',
        loadChildren: () => import('./edit-intake-form/edit-intake-form.module').then(m => m.EditIntakeFormModule)
      },
      { path: 'patients', loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule) },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
