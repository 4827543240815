import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterial } from '../../angular-material';
import { SharedRoutingModule } from './shared-routing.module';
import { AppBlankComponent } from './components/blank/blank.component';
import { FullComponent } from './components/full/full.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MenuItems } from '../../utils/menu-items/menu-items';
import { FlexLayoutModule } from "@angular/flex-layout";
import { McBreadcrumbsModule } from 'ngx-breadcrumbs';
import { MatToolbarModule, MatSidenavModule, MatListModule, MatButtonModule, MatIconModule } from "@angular/material";

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SafePipe } from 'src/app/utils/pipes/safe.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppBlankComponent,
    FullComponent,
    SpinnerComponent,
    SafePipe,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    AngularMaterial,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    McBreadcrumbsModule.forRoot()

  ],
  exports: [
    FullComponent,
    AppBlankComponent,
    SpinnerComponent,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
  ],
  providers: [
    MenuItems,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ]
})
export class SharedModule {

}
