import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";

let baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  private loginHeader = new HttpHeaders({ "content-type": "application/x-www-form-urlencoded", "Accept": "application/json", "Authorization": "Basic aGVhbHRoOmhlYWx0aC10ZWxlY2hlY2t1cA==" })
  private header = new HttpHeaders({ "content-type": "application/json", "Accept": "application/json" })
  private fileHeader = new HttpHeaders({ "Accept": "application/json" })

  postLogin(endpoint, data) {
    let url = baseUrl + endpoint
    return this.http.post<any>(url, data, { headers: this.loginHeader })
      // .pipe(catchError(this.handleError))
  }

  postSignUp(endpoint, data) {
    let url = baseUrl + endpoint
    return this.http.post(url, data, { headers: this.header })
  }

  post(endpoint, data) {
    let url = baseUrl + endpoint
    return this.http.post(url, data, { headers: this.header })
  }

  get(endpoint) {
    let url = baseUrl + endpoint
    return this.http.get(url, { headers: this.header })
  }

  patch(endpoint, data) {
    let url = baseUrl + endpoint
    return this.http.patch(url, data, { headers: this.header })
  }

  put(endpoint, data) {
    let url = baseUrl + endpoint
    return this.http.put(url, data, { headers: this.header })
  }

  delete(endpoint) {
    let url = baseUrl + endpoint
    return this.http.delete(url, { headers: this.header })
  }

  postFile(endpoint, data) {
    let url = baseUrl + endpoint
    return this.http.post(url, data, { headers: this.fileHeader })
  }

  putFile(endpoint, data) {
    let url = baseUrl + endpoint
    return this.http.put(url, data, { headers: this.fileHeader })
  }

  getAccessToken() {
    return JSON.parse(localStorage.getItem("token"))
  }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.status === 404) {
      console.log("server side error", errorResponse.error)
      return throwError(errorResponse.error.message || "Server side error");
    } else if (errorResponse.error instanceof ErrorEvent) {
      console.log("client side error", errorResponse.error.message)
      return throwError(errorResponse.error.message || "Client side error");
    }
  }

  isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user != null)
      return true;
    return false;
  }
}
