import { AppointmentSuccessfullPopupComponent } from '../appointment-successfull-popup/appointment-successfull-popup.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/core/services/http/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export interface DialogData {
  appointment: any
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd DD MMM yyyy',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-reschedule-popup',
  templateUrl: './reschedule-popup.component.html',
  styleUrls: ['./reschedule-popup.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class ReschedulePopupComponent implements OnInit {

  bookAppointmentForm: FormGroup;
  slots: Array<any> = [];
  user: any;
  date: any;
  showLaoder = false;
  finalSlots: any[];
  // slot;
  todayDate = new Date();
  timeZone;
  constructor(
    public dialogRef: MatDialogRef<ReschedulePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private http: HttpService,
    private toast: ToastrService,
    private router: Router,
    private actRoute: ActivatedRoute,
    public dialog: MatDialog) {
    this.onDateChange(data.appointment.appointmentDate);
    // this.slot = data.appointment.slotId[0]._id;
  }

  ngOnInit() {
    this.http.get('api/organization/timezone').subscribe(res => {
      console.log(res);
      if (res['status'] === 200) {
        this.timeZone = res['data'].timeZone;
      }
    });

    this.bookAppointmentForm = this.fb.group({
      date: ['', Validators.required],
      time: ['', Validators.required]
    },
      {
        validator: []
      });
    this.bookAppointmentForm.patchValue({
      date: this.data.appointment.appointmentDate
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.rescheduleAppointment();
  }

  rescheduleAppointment() {
    if (this.bookAppointmentForm.invalid) {
      return;
    }
    const payload = {
      "appointmentId": this.data.appointment._id,
      "doctorId": this.data.appointment.doctorId._id,
      "patientId": this.data.appointment.patientId._id,
      "slotId": this.bookAppointmentForm.value.time,
      "status": "RESCHEDULED",
      "appointmentDate": moment(this.bookAppointmentForm.value.date).format('YYYY-MM-DD'),
      timeZone: this.timeZone
    }
    this.showLaoder = true;
    this.http.put('api/admin/rescheduleAppointments', payload).subscribe((response: any) => {
      this.showLaoder = false;
      if (response.status === 200) {
        this.dialogRef.close("success");
        const dialogRef2 = this.dialog.open(AppointmentSuccessfullPopupComponent, {
          data: { response: "response", type: "put" }
        });
      } else {
        this.showLaoder = false;
        this.toast.error(response.message);
        this.dialogRef.close();
      }
    }, (error) => {
      this.showLaoder = false;
      this.dialogRef.close();
      this.toast.error(error, '', { timeOut: 3000 });
    })
  }

  onDateChange(date) {
    let d = moment(new Date()).format('HH:mm:ss');
    const selectedDate = moment(date + " " + d).format('YYYY-MM-DD HH:mm:ss');
    this.finalSlots = [];
    this.showLaoder = true;
    const data = {
      doctorId: this.data.appointment.doctorId._id,
      date: moment(selectedDate).format()
    }
    this.http.post(`api/doctors/slots`, data).subscribe((response: any) => {
      this.showLaoder = false;
      if (response.status === 200) {
        this.slots = response.data;
        console.log(this.slots);
        this.finalSlots = _.cloneDeep(this.slots.filter((slot) => {
          return !(slot.status === 'INACTIVE' || slot.isTimeout === true);
        }));
      } else {
        this.toast.error(response.message);
      }
    }, (error) => {
      this.showLaoder = false;
      this.toast.error(error);
    });
  }

}

