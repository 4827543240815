import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from '../helper/helper.service';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  constructor(private helper: HelperService){}

  transform(value: any, args?: any): any {
    return this.helper.dateFormat(value, args);
  }

}
