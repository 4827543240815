import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/core/services/http/http.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
url;
  user: {
    id: string;
    uid: string,
    fullName: string;
    email: string;
    profileImage: string,
    imageUrl: string
  };
  showLaoder = false;

  constructor(
    private http: HttpService,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private router: Router
  ) {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      this.user = user.userDetails;
    } else {
      this.http.get('api/user/token').subscribe((response: any) => {
        if (response.status === 200) {
          this.user = response.data;
        }
      });
    }

    this.loaderService.isLoading.subscribe(v => {
      setTimeout(() => {
        this.showLaoder = v;
      }, 0);
    });

    this.router.events.subscribe(val => {
      this.url = this.router.url;
    });
  }

  ngOnInit() {

  }

  goTo(route) {
    this.router.navigate([route]);
  }

  logout() {
    this.authService.logout();
  }

}
