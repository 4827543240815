import { Injectable } from '@angular/core';
import * as moment from 'moment'

@Injectable({ providedIn: 'root' })
export class HelperService {

  constructor() { }

  dateFormat(date, format = 'L') {
    return moment(date).format(format);
  }
}
