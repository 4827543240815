import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { HttpService } from 'src/app/core/services/http/http.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  url;
  user: any;
  activeMediaQuery = '';
  watcher: Subscription;
  showLaoder: boolean = false;
  isDashoard = true;
  constructor(
    private http: HttpService,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    mediaObserver: MediaObserver
  ) {

    const user = JSON.parse(localStorage.getItem('currentUser'));
    if (user) {
      this.user = user['userDetails']
    } else {
      this.http.get('api/user/token').subscribe((response: any) => {
        if (response.status === 200) {
          this.user = response.data;
        }
      });
    }

    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      // console.log(change.mqAlias);
      this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
    });
  }

  ngOnInit() {
    this.url = this.router.url;
    this.router.events.subscribe(val => {
      this.url = this.router.url;
      if (this.url === '/app/dashboard') {
        this.isDashoard = true;
      } else {
        this.isDashoard = false;
      }
    });
  }

  logout() {
    const message = `Are you sure you want to logout?`;
    const dialogData = new ConfirmDialogModel('Confirm Action', message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      console.log(result);
      if (result) {
        this.authService.logout();
      }
    });
  }

  // openIntakeForm() {
  //   const dialogRef = this.dialog.open(CreateIntakeFormComponent, {
  //     autoFocus: false
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }


  gotoDashboard() {
    this.router.navigate(['/app/dashboard']);
  }

}
