import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/core/services/http/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export interface DialogData {
  doctor: string;
  appointment: any;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd DD MMM yyyy',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreateAppointmentComponent implements OnInit {

  bookAppointmentForm: FormGroup;
  slots: Array<any> = [];
  patient = null;
  date: any;
  showLaoder = false;
  finalSlots: any[];
  todayDate = new Date();
  doctor;
  success = false;
  timeZone;
  constructor(
    public dialogRef: MatDialogRef<CreateAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private http: HttpService,
    private toast: ToastrService,
    public dialog: MatDialog) {
    // this.onDateChange(data.appointment.appointmentDate);
    // this.slot = data.appointment.slotId[0]._id;
    this.doctor = data.doctor;
  }

  ngOnInit() {
    this.http.get('api/organization/timezone').subscribe(res => {
      console.log(res);
      if (res['status'] === 200) {
        this.timeZone = res['data'].timeZone;
      }
    });
    // this.timeZone = moment().utcOffset();
    // console.log(this.timeZone);

    this.bookAppointmentForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      fullName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      gender: ['', [Validators.required]],
      dob: ['', Validators.required],
      mobileNo: ['', Validators.required],
      appointmentDate: [new Date(), Validators.required],
      time: ['', Validators.required]
    });

    this.onDateChange(moment(new Date()).format('YYYY-MM-DD'));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  searchPatient() {
    const email = this.bookAppointmentForm.value.email;
    if (email === '' || email === null || email === undefined) {
      return;
    }
    this.http.get('users/user?email=' + email).subscribe(res => {
      console.log(res);
      if (res['status'] === 200) {
        this.patient = res['data'];
        this.bookAppointmentForm.patchValue({
          fullName: res['data'].fullName,
          dob: res['data'].dob,
          mobileNo: res['data'].mobileNo,
          gender: res['data'].gender
        });
      } else if (res['status'] === 204) {
        this.patient = null;
      }
    }, err => {
      this.patient = null;
      this.toast.error('Something went wrong');
    });
  }
  bookAppointment(form) {
    // this.success = true;
    // return;
    if (this.bookAppointmentForm.invalid) {
      return;
    }

    const payload = {
      doctorId: this.doctor._id,
      // patientId: null,
      email: form.value.email,
      fullName: form.value.fullName,
      gender: form.value.gender,
      mobileNo: form.value.mobileNo,
      dob: moment(form.value.dob).format('YYYY-MM-DD'),
      slotId: this.bookAppointmentForm.value.time,
      status: 'BOOKED',
      appointmentDate: moment(form.value.appointmentDate).format('YYYY-MM-DD'),
      timeZone: this.timeZone
    };
    // if (this.patient !== null) {
    //   payload.patientId = this.patient._id;
    // }
    console.log(payload);

    this.showLaoder = true;
    this.http.post('api/admin/walking/appointments', payload).subscribe((response: any) => {
      this.showLaoder = false;
      if (response.status === 200) {
        console.log('success');
        // this.dialogRef.close();
        this.success = true;
      } else {
        this.showLaoder = false;
        this.toast.error(response.message);
        this.dialogRef.close();
      }
    }, (error) => {
      this.showLaoder = false;
      this.dialogRef.close();
      this.toast.error(error, '', { timeOut: 3000 });
    });
  }

  onDateChange(date) {
    let d = moment(new Date()).format('HH:mm:ss');
    const selectedDate = moment(date + ' ' + d).format('YYYY-MM-DD HH:mm:ss');
    this.finalSlots = [];
    this.showLaoder = true;
    const data = {
      doctorId: this.doctor._id,
      date: moment(selectedDate).format()
    };
    this.http.post(`api/doctors/slots`, data).subscribe((response: any) => {
      this.showLaoder = false;
      if (response.status === 200) {
        this.slots = response.data;
        console.log(this.slots);
        this.finalSlots = _.cloneDeep(this.slots.filter((slot) => {
          return !(slot.status === 'INACTIVE' || slot.isTimeout === true);
        }));
      } else {
        this.toast.error(response.message);
      }
    }, (error) => {
      this.showLaoder = false;
      this.toast.error(error);
    });
  }


}
